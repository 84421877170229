import React from 'react';

const VkSvg = () => {
    return (
        <svg
            viewBox="0 0 38 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className='social__link-svg'
        >
            <path
                d="M37.134 1.53079C37.395 0.648998 37.134 0 35.8783 0H31.7233C30.6651 0 30.1784 0.557291 29.9174 1.17807C29.9174 1.17807 27.8011 6.33478 24.8101 9.67853C23.8436 10.645 23.3992 10.9554 22.8701 10.9554C22.6091 10.9554 22.207 10.645 22.207 9.76318V1.53079C22.207 0.472639 21.9107 0 21.036 0H14.5037C13.8406 0 13.4455 0.493803 13.4455 0.952334C13.4455 1.95405 14.9411 2.18684 15.0963 5.00857V11.1317C15.0963 12.472 14.8564 12.7189 14.3273 12.7189C12.9165 12.7189 9.48806 7.54107 7.45642 1.61544C7.04727 0.465586 6.64517 0 5.57997 0H1.42497C0.239847 0 0 0.557291 0 1.17807C0 2.27855 1.41086 7.74565 6.56757 14.9693C10.003 19.9002 14.8423 22.5738 19.2442 22.5738C21.8896 22.5738 22.2141 21.9813 22.2141 20.9584C22.2141 16.2461 21.9742 15.8017 23.3004 15.8017C23.9142 15.8017 24.9723 16.1121 27.4413 18.4894C30.263 21.3111 30.7286 22.5738 32.3088 22.5738H36.4638C37.6489 22.5738 38.2485 21.9813 37.9029 20.8103C37.1128 18.3483 31.7727 13.2833 31.5328 12.9447C30.9191 12.1546 31.0955 11.8019 31.5328 11.0965C31.5399 11.0894 36.6119 3.95042 37.134 1.53079Z"
                fill="white"
            />
        </svg>
    );
};

export default VkSvg;