import uber from '../assets/img/projects/Uber.png';
import globalOpt from '../assets/img/projects/Global_opt.png';
import pulse from '../assets/img/projects/Pulse.png';
import food from '../assets/img/projects/Food.png';
import picture from '../assets/img/projects/Picture.png';
import smartWath from '../assets/img/projects/SmartWatch.png';
import portfolio from '../assets/img/projects/Portfolio_1.0.png';
import portfolio2 from '../assets/img/projects/Portfolio_2.0.png';
import marvelPortal from '../assets/img/projects/MarvelPortal.png';
import apple from '../assets/img/projects/apple_img.png';
import posts from '../assets/img/projects/Posts.png';
import coffeeBase from '../assets/img/projects/CoffeBase.png';
import tzeezotje from '../assets/img/projects/Tzeezotje.png';
import carShowcase from '../assets/img/projects/CarShowcase.png';
import adminPanel from '../assets/img/projects/Admin_panel.png';
import Nike from '../assets/img/projects/Nike.png';

const projects = [
    {   id: 1,
        title: 'Admin',
        imgSmal: adminPanel,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            },
            {
                name: 'Syncfusion-EJ2',
                color: 'green-text-hashtag'
            },
            {
                name: 'TailwindCss',
                color: 'orange-text-hashtag'
            },
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/dashboard_admin-react-js',
        liveSiteLink: 'https://test4.artem-bakhmatov.ru/'
    },
    {   id: 2,
        title: 'Nike',
        imgSmal: Nike,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            },
            {
                name: 'TailwindCss',
                color: 'orange-text-hashtag'
            },
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/nike_react-js',
        liveSiteLink: 'https://test5.artem-bakhmatov.ru/'
    },
    {   id: 3,
        title: 'Uber',
        imgSmal: uber,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'CSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Bootstrap',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Artembakhmatov.github.io',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/Uber/'
    },
    {
        id: 4,
        title: 'Pulse',
        imgSmal: pulse,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Jquery',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/PULSE',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/Pulse/'
    },
    {
        id: 5,
        title: 'Global Opt',
        imgSmal: globalOpt,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Jquery',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Global-Opt',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/Global_Opt/'
    },
    {
        id: 6,
        title: 'Food',
        imgSmal: food,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Webpack',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/js-practica',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/food/'
    },
    {
        id: 7,
        title: 'Picture',
        imgSmal: picture,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Bootstrap',
                color: 'blue-text-hashtag'
            },
            {
                name: 'Gulp',
                color: 'green-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Project2.A-voluminous-project-in-a-classic-style',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/Picture/'
    },
    {
        id: 8,
        title: 'Portfolio_1.0',
        imgSmal: portfolio,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/PORTFOLIO',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/'
    },
    {
        id: 9,
        title: 'Portfolio_2.0',
        imgSmal: portfolio2,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/portfolio-react',
        liveSiteLink: ''
    },
    {
        id: 10,
        title: 'Online store',
        imgSmal: smartWath,
        web: 'Store',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Gulp',
                color: 'blue-text-hashtag'
            },
            {
                name: 'Webpack',
                color: 'green-text-hashtag'
            },
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/SmartWatch',
        liveSiteLink: 'https://portfolio.artem-bakhmatov.ru/Projects/SmartWatch/'
    }, 
    {
        id: 11,
        title: 'Marvel Portal',
        imgSmal: marvelPortal,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            },
            {
                name: 'API',
                color: 'green-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Portal_Marvel-ReactJS',
        liveSiteLink: 'https://test1.artem-bakhmatov.ru/'
    }, 
    {
        id: 12,
        title: 'Apple Website',
        imgSmal: apple,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            },
            {
                name: 'GSAP',
                color: 'green-text-hashtag'
            },
            {
                name: 'Three.js',
                color: 'orange-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/childhood_Wordpress',
        liveSiteLink: 'https://iphone.artem-bakhmatov.ru/'
    }, 
    {
        id: 13,
        title: 'Posts',
        imgSmal: posts,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            },
            {
                name: 'JSONPlaceholder-API',
                color: 'green-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Posts_JSONPlaceholder-ReactJS',
        liveSiteLink: 'https://test3.artem-bakhmatov.ru/'
    }, 
    {
        id: 14,
        title: 'Coffee-Base',
        imgSmal: coffeeBase,
        web: 'React',
        skills: [
            {
                name: 'React',
                color: 'blue-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/coffee_shop-react',
        liveSiteLink: 'https://test2.artem-bakhmatov.ru/'
    }, 
    {
        id: 15,
        title: 'Tzeezotje',
        imgSmal: tzeezotje,
        web: 'Landing',
        skills: [
            {
                name: 'HTML',
                color: 'blue-text-hashtag'
            },
            {
                name: 'SCSS',
                color: 'green-text-hashtag'
            },
            {
                name: 'JS',
                color: 'orange-text-hashtag'
            },
            {
                name: 'Gulp',
                color: 'blue-text-hashtag'
            },
            {
                name: 'Webpack',
                color: 'green-text-hashtag'
            },
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Tzeezotje-Landing',
        liveSiteLink: 'https://tzeezotje.artem-bakhmatov.ru/'
    }, 
    {
        id: 16,
        title: 'Car-Showcase',
        imgSmal: carShowcase,
        web: 'React',
        skills: [
            {
                name: 'NextJS',
                color: 'blue-text-hashtag'
            },
            {
                name: 'TypeScript',
                color: 'green-text-hashtag'
            },
            {
                name: 'Tailwindcss',
                color: 'orange-text-hashtag'
            }
        ],
        gitHubLink: 'https://github.com/ArtemBakhmatov/Car_Showcase-Next-JS',
        liveSiteLink: 'https://car-showcase-next-js-mu.vercel.app/'
    }, 
];

export default projects;